import React, { useState } from 'react';
import profilePhoto from "./assets/hector_photo.jpg"
import {
    Globe,
    Github,
    Youtube,
    Twitter,
    Linkedin,
    ExternalLink,
    ChevronDown,
    ChevronUp,
    Award,
    Rocket,
    Cloud,
    Brain,
    Book,
    MapPin
} from 'lucide-react';

const Home = () => {
    const [expandedCards, setExpandedCards] = useState({});

    const toggleCard = (index) => {
        setExpandedCards(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const projects = [
        {
            title: "Yumi Language Translator",
            description: "State of the Art Model for Aguaruna-Spanish Translation.",
            link: "https://huggingface.co/hectordiazgomez/nllb-spa-awa-v3",
            icon: Globe,
            details: {
                subtitles: [],
                links: [
                    { text: "Source code", url: "https://colab.research.google.com/drive/1rrUsF_seA7y9eX8X2G1CpyfqfMC4bztt" },
                    { text: "BLEU eval", url: "https://colab.research.google.com/drive/13POtxt6SNf3moHohAzR9Jhibs9RhsmBA?usp=sharing" }
                ],
                extendedDescription: "Agreement reached with the Ministry of Education of Peru to continue further fined tuning it."
            }
        },
        {
            title: "Gaia",
            description: "A no-code platform for training and deploying neural machine translation models. ",
            link: "https://gaia-ml.com",
            icon: Rocket,
            details: {
                links: [
                    { text: "Website", url: "https://gaia-ml.com" },
                ],
                extendedDescription: "Pivoted towards a translation agent builder. Students and researchers from 3 universities in Peru built 7 translation agents for 7 languages that had no previous translation technology.."
            }
        },
        {
            title: "Fino",
            description: "A Heroku-like platform but up to 8x less expensive because I used Vultr as cloud provider.",
            link: "https://fino-cloud.com/",
            icon: Cloud,
            details: {
                links: [
                    { text: "Website", url: "https://fino-cloud.com/" }
                ],
                extendedDescription: "Deploy nodeJS servers in minutes. Forget about EC2 instances, load balancers, hosted zones, etc,. and deploy with a couple of clicks"
            }
        },
        {
            title: "VortiX",
            description: "VortiX is an AI-Powered academic search engine that provides answers based on +220 million papers",
            link: "https://vortix.io/",
            icon: Brain,
            details: {
                links: [
                    { text: "Google Play", url: "https://vortix.io/" }
                ],
                extendedDescription: "VortiX is an AI-Powered academic search engine that provides answers based on +220 million academic publications"
            }
        },
        {
            title: "Athena",
            description: "Free legal assistance for citizens in Peru. Ranked in the top 20 first places globally in the Digital Gamechangers Award by the United Nations Development Programme.",
            link: "https://atena-web.app",
            icon: Award,
            details: {
                links: [
                    { text: "", url: "https://atena-web.app" }
                ],
                extendedDescription: "Athena relies on Retrieval Augmented Generation to provide free legal assistance and display the sources alongside the responses."
            }
        },
        {
            title: "EduX",
            description: "Get ready for the Duolingo English Test with our app. Trusted by +30K learners. [Discontinued]",
            link: "https://luia.app/",
            icon: Book,
            details: {
                links: [
                    { text: "Google Play", url: "https://play.google.com/store/apps/details?id=com.edux.quizui" }
                ],
                extendedDescription: "EduX is a comprehensive preparation platform for the Duolingo English Test. It offers practice tests, personalized study plans, and interactive lessons to help learners improve their English skills and achieve their desired scores."
            }
        },
        {
            title: "Amazonas Travel Mark",
            description: "B2G Proyect: Mobile app for the Regional Government of Amazonas.",
            link: "https://play.google.com/store/apps/details?id=com.amazonas.app",
            icon: MapPin,
            details: {
                extendedDescription: "Amazonas Travel Mark is a B2G (Business-to-Government) project developed for the Regional Government of Amazonas. This mobile app serves as a comprehensive guide for tourists, showcasing the region's attractions, accommodations, and cultural experiences."
            }
        }
    ];

    const mediaAppearances = [
        {
            title: "Speech at the Palace of Government of Peru",
            description: "Invited by Peru's president Dina Boluarte",
            link: "https://fb.watch/rF17c68vcY/",
            icon: Award
        },
        {
            title: "Book: Testimonies of the power of Education",
            description: "16 Stories that Inspire (Me included). By Ministry of Education of Peru",
            link: "https://www.pronabec.gob.pe/descargas2024/Mayo/Libro%20-%20Testimonios%20del%20poder%20de%20la%20educacio%CC%81n.pdf#page=87",
            icon: Book
        }
    ]

    const socialLinks = [
        { icon: Linkedin, href: "https://www.linkedin.com/in/hector-alberto-diaz-gomez-56b220216", color: "text-blue-700" },
        { icon: Github, href: "https://github.com/hectordiazgomez", color: "text-gray-700" },
        { icon: Youtube, href: "https://www.youtube.com/@hectoralbertodiazgomez3490", color: "text-red-600" },
        { icon: Twitter, href: "https://twitter.com/HctorAlber2763?t=R6-urnekzZXFxf3jNNJsOw&s=09", color: "text-blue-400" }
    ];


    return (
        <div className="min-h-screen font-sans bg-gray-50">
            <header className="bg-white/80 backdrop-blur-sm border-b border-gray-100">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <div className="flex justify-between items-center">
                        <div className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text">
                            <h1 className="text-2xl font-bold text-transparent">HD</h1>
                        </div>
                        <nav className="flex items-center space-x-6">
                            {socialLinks.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-500 hover:text-gray-700 transition-colors"
                                >
                                    <item.icon className="w-5 h-5" />
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </header>
            <main className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <section className="mb-16">
                    <div className="bg-white rounded-2xl p-8 shadow-sm border border-gray-100">
                        <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
                            <img
                                src={profilePhoto}
                                alt="Hector Alberto Diaz Gomez"
                                className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-xl"
                            />
                            <div className="flex-1 text-center md:text-left">
                                <h2 className="text-4xl font-bold text-gray-900 mb-2">Hector Diaz</h2>
                                <p className="text-xl text-gray-600 font-medium mb-4">
                                    Artificial intelligence, cloud computing and, of course, economics
                                </p>
                                <div className="space-y-1 text-gray-500">
                                    <p>Emergent Ventures Fellow • Magnificent Fellowship winner</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-16">
                    <h2 className="text-xl font-semibold text-gray-900 mb-6 tracking-tight">Media Appearances</h2>
                    <div className="grid gap-4">
                        {mediaAppearances.map((item, index) => (
                            <div key={index} className="group bg-white rounded-xl p-6 transition-all border border-gray-100 hover:border-blue-100 hover:shadow-sm">
                                <div className="flex items-start space-x-4">
                                    <div className="bg-blue-50 p-2.5 rounded-lg flex-shrink-0">
                                        <item.icon className="w-5 h-5 text-blue-600" />
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900 mb-1.5">{item.title}</h3>
                                        <p className="text-gray-500 leading-relaxed">{item.description}</p>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="inline-flex items-center mt-3 text-blue-600 hover:text-blue-700 font-medium"
                                        >
                                            View More
                                            <ExternalLink className="ml-1.5 w-4 h-4" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <section className="mb-16">
                    <h2 className="text-xl font-semibold text-gray-900 mb-6 tracking-tight">Projects</h2>
                    <div className="grid gap-4">
                        {projects.map((project, index) => (
                            <div key={index} className="bg-white rounded-xl p-6 border border-gray-100 hover:shadow-sm transition-all">
                                <div className="flex items-start space-x-4 mb-4">
                                    <div className="bg-blue-50 p-2.5 rounded-lg flex-shrink-0">
                                        <project.icon className="w-5 h-5 text-gray-700" />
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="text-lg font-medium text-gray-900 mb-1">{project.title}</h3>
                                        <p className="text-gray-500">{project.description}</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center">
                                    <a
                                        href={project.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center text-gray-600 hover:text-blue-700 font-medium"
                                    >
                                        View Project
                                        <ExternalLink className="ml-1.5 w-4 h-4" />
                                    </a>
                                    <button
                                        onClick={() => toggleCard(index)}
                                        className="text-gray-600 hover:text-blue-700 font-medium flex items-center group"
                                    >
                                        {expandedCards[index] ? (
                                            <>
                                                Collapse
                                                <ChevronUp className="ml-1.5 w-4 h-4 transition-transform group-hover:-translate-y-0.5" />
                                            </>
                                        ) : (
                                            <>
                                                Expand
                                                <ChevronDown className="ml-1.5 w-4 h-4 transition-transform group-hover:translate-y-0.5" />
                                            </>
                                        )}
                                    </button>
                                </div>
                                {expandedCards[index] && (
                                    <div className="mt-4 pt-4 border-t border-gray-100 animate-slideDown">
                                        <p className="text-gray-500 leading-relaxed mb-4">{project.details.extendedDescription}</p>
                                        <div className="flex flex-wrap gap-3">
                                            {project.details.links?.map((link, i) => (
                                                <a
                                                    key={i}
                                                    href={link.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="px-3 py-1.5 bg-blue-50 text-blue-700 rounded-lg hover:bg-blue-100 transition-colors"
                                                >
                                                    {link.text}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </section>
            </main>
            <footer className="border-t border-gray-100 bg-white/50 backdrop-blur-sm">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    <div className="flex flex-col items-center text-center">
                        <p className="text-gray-600 mb-6">Let's connect</p>
                        <div className="flex space-x-5">
                            {socialLinks.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-500 hover:text-gray-700 transition-colors p-2 rounded-full hover:bg-gray-50"
                                >
                                    <item.icon className="w-5 h-5" />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Home;